<template>
  <div class="footer-btn">
    <div class="footer-btn-wrap">
      <Button plain
              round
              class="cancel-btn"
              @click="cancel"
              size="small"
              :type="cancelType">{{cancelText}}</Button>
      <Button round
              color="#DA3738"
              class="ok-btn"
              size="small"
              @click="ok"
              :type="okType">{{okText}}</Button>
    </div>
    <div style="height: 98px;"></div>
  </div>
</template>

<script>
import { Button } from 'vant'
export default {
  components: {
    Button
  },
  props: {
    cancelType: {
      type: String,
      default: 'default'
    },
    okType: {
      type: String,
      default: 'info'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    okText: {
      type: String,
      default: '确认'
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    ok () {
      this.$emit('ok')
    }
  }
}
</script>

<style lang="less" scope>
.footer-btn-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 98px;
  background: #fff;
  border-top: 1px solid #eee;
  .vant-btn {
    height: 20px;
    line-height: 20px;
  }
  .cancel-btn {
    width: 30%;
    font-size: 24px;
    font-weight: bold;
  }
  .ok-btn {
    width: 30%;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>