/*
 * @Author: your name
 * @Date: 2021-05-27 17:31:10
 * @LastEditTime: 2021-06-07 15:31:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \04eos-app\src\api\clue\index.js
 */

import request from '@/utils/request'
import Api from '@/api/base/api-config'

// 新建搜索-Leads搜索
export const leadssearch = (params) => {
  return request({
    method: 'GET',
    url: Api.clue.leadssearch,
    params
  })
}

// 获取商机活动
export const getEntertainment = (params) => {
  return request({
    method: 'GET',
    url: Api.clue.getEntertainment,
    params
  })
}

export const leadssave = (data) => {
  return request({
    method: 'post',
    url: Api.clue.leadssave,
    data
  })
}
// 搜索页面的认领
export const leadsapply = data => {
  return request({
    method: 'put',
    url: Api.clue.leadsapply,
    data
  })
}
// 获取客户详情
export const accountDetail = params => {
  return request({
    method: 'get',
    url: Api.clue.accountDetail,
    params
  })
}
// 获取poc详情
export const pocInfo = params => {
  return request({
    method: 'get',
    url: Api.clue.pocInfo,
    params
  })
}

// 勘测单
export const OrderMessage = (data) => {
  return request({
    method: 'post',
    url: Api.clue.orderMessage,
    data
  })
}

// 获取客户详情leads
export const ledasoppLeads = (page, params) => {
  return request({
    method: 'post',
    url: `${Api.clue.ledasoppLeads}?page=${page.page}&size=${page.size}`,
    data: {
      accoundId: params.accoundId
    }
  })
}

// 线索跟进记录
export const leadsfollowuplist = (page, params) => {
  return request({
    method: 'post',
    data: {
      accoundId: params.accoundId,
      leadsoppId: params.leadsoppId
    },
    url: `${Api.clue.leadsfollowuplist}?page=${page.page}&size=${page.size}`
  })
}
// 商机跟进记录
export const oppfollowupList = (page, params) => {
  return request({
    method: 'post',
    data: {
      leadsoppId: params.leadsoppId
    },
    url: `${Api.clue.oppfollowupList}?page=${page.page}&size=${page.size}`
  })
}
// 线索重新分配
export const leadsagainsend = params => {
  return request({
    method: 'put',
    data: params,
    url: Api.clue.leadsagainsend
  })
}
// 商机重新分配
export const oppsagainsend = params => {
  return request({
    method: 'put',
    data: params,
    url: Api.clue.oppsagainsend
  })
}
// 商机列表
export const oppsList = (page, params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.clue.oppsList}?page=${page.page}&size=${page.size}`
  })
}

// 试乘试驾
export const testdriveSearch = (params) => {
  return request({
    method: 'get',
    url: `${Api.clue.testdriveSearch}?accountPhone=${params.accountPhone}&page=${params.page}&size=${params.size}`
  })
}

// 参加活动
export const joinActive = (params) => {
  return request({
    method: 'get',
    url: `${Api.clue.joinActive}?page=${params.page}&size=${params.size}&accoundId=${params.accoundId}`
  })
}

// 勘测单
export const orderMessage = params => {
  return request({
    method: 'post',
    data: params,
    url: Api.clue.OrderMessage
  })
}

// 客户动向
export const accounttrend = (params) => {
  return request({
    method: 'get',
    url: `${Api.clue.accounttrend}?page=${params.page}&size=${params.size}&accountId=${params.accountId}`
  })
}

// POC详情
export const oppPoc = (params) => {
  return request({
    method: 'Get',
    url: `${Api.clue.oppPoc}?oppid=${params.oppid}`
  })
}

export const leadsbydrsave = (data) => {
  return request({
    method: 'POST',
    url: Api.clue.leadsbydrsave,
    data
  })
}

export const leadsbyordersave = (data) => {
  return request({
    method: 'POST',
    url: Api.clue.leadsbyordersave,
    data
  })
}

// 编辑客户信息
export const accountupdate = (params) => {
  return request({
    method: 'put',
    data: params,
    url: Api.general.accountupdate
  })
}