import request from '@/utils/request'
import Api from '@/api/base/api-config'

// 充电查询
export const getChargeSearch = (page, params) => {
  const name = params.name !== '' ? `&name=${params.name.trim()}` : ''
  return request({
    method: 'get',
    url: `${Api.common.getChargeSearch}?page=${page.page}&size=${page.size}${name}`
  })
}

// LeadsSearch搜索
export const LeadsSearch = (params) => {
  const phone = params.phone !== '' ? `phone=${params.phone}` : ''
  return request({
    method: 'get',
    url: `${Api.common.LeadsSearch}?${phone}`
  })
}

// account搜索
export const accountSearch = (params) => {
  const phone = params.phone !== '' ? `phone=${params.phone}` : ''
  return request({
    method: 'get',
    url: `${Api.common.accountSearch}?${phone}`
  })
}

// Opp搜索
export const oppSearch = (params) => {
  const phone = params.phone !== '' ? `phone=${params.phone}` : ''
  return request({
    method: 'get',
    url: `${Api.common.oppSearch}?${phone}`
  })
}

// 淘客池leaders
export const taokePoll = (page, params) => {
  return request({
    method: 'get',
    url: `${Api.common.taokePoll}?page=${page.page}&size=${page.size}&type=${params.type}`
  })
}

// 线索跟进
export const clueFollow = (data) => {
  return request({
    url: Api.common.clueFollow,
    method: 'put',
    data
  })
}

//商机跟进
export const oppFollowUp = (data) => {
  return request({
    method: 'put',
    url: Api.common.oppFollowUp,
    data
  })
}

//淘客池线索(opp-LongTerm)
export const taokePollopp = (page) => {
  return request({
    method: 'get',
    url: `${Api.common.taokePollopp}?page=${page.page}&size=${page.size}`
  })
}

// 商机列表
export const oppsList = (page, params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.common.oppsList}?page=${page.page}&size=${page.size}`
  })
}

//线索列表
export const leadsList = (page, params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.common.leadsList}?page=${page.page}&size=${page.size}`
  })
}

//订单列表
export const orderList = (params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.common.orderList}`
  })
}

// 商机下发分配列表
export const oppSendList = (page, params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.common.oppSendList}?page=${page.page}&size=${page.size}`
  })
}

// 线索下发分配列表
export const leadsSendList = (page, params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.common.leadsSendList}?page=${page.page}&size=${page.size}`
  })
}

// 线索下发分配动作
export const leadssend = params => {
  return request({
    method: 'put',
    data: params,
    url: Api.common.leadssend
  })
}

// 商机下发分配动作
export const oppsend = params => {
  return request({
    method: 'put',
    data: params,
    url: Api.common.oppsend
  })
}

//获取组织/组织树
export const organization = (params) => {
  return request({
    method: 'GET',
    url: Api.common.organization,
    params: params
  })
}


// 组织-获取组织下用户列表
export const userserList = (params) => {
  return request({
    method: 'GET',
    url: Api.common.userList,
    params: params
  })
}

//金融计算器接口 --工程车型
export const carSeries = (params) => {
  return request({
    method: 'GET',
    url: `${Api.common.carseries}?channel=${params.channel}`
  })
}

// 库存列表
export const inventoryList = (page, params) => {
  return request({
    method: 'post',
    data: {
      'page': {
        size: page.size,
        index: page.page
      },
      ...params
    },
    url: `${Api.order.inventoryList}`
  })
}

// 门店组织List API 及 交付中心
export const getOrgList = (params) => {
  // console.log(params)
  return request({
    url: `${Api.order.orgList}?channel=${params.channel}&orgType=${params.orgType}`,
    method: 'GET'
  })
}

// 库存列表-工程车型
export const getEngineeringModels = (params) => {
  return request({
    url: Api.common.carseries,
    method: 'GET',
    params
  })
}

// 库存列表-基础车型
export const getBasicModels = (params) => {
  return request({
    url: Api.order.basicModels,
    params,
    method: 'GET'
  })
}

// 订单详情
export const orderDetaile = (params) => {
  return request({
    method: 'get',
    params,
    url: `${Api.common.orderDetaile}`
  })
}

// 金融方案
export const getLoan = (params) => {
  return request({
    url: Api.order.getLoan,
    method: 'POST',
    data: params
  })
}

// 配车
export const preassign = (params) => {
  return request({
    method: 'post',
    data: params,
    url: `${Api.order.preassign}`
  })
}

// 获取上牌城市
export const getBrandCity = () => {
  return request({
    method: 'get',
    url: Api.common.carbrandcity
  })
}

// 获取省市
export const areatree = () => {
  return request({
    method: 'get',
    url: Api.common.areatree
  })
}

// POC保存
export const oppSave = (params) => {
  return request({
    method: 'post',
    data: params,
    url: Api.common.oppSave
  })
}

// POC详情
export const oppPoc = (params) => {
  return request({
    method: 'Get',
    url: `${Api.common.oppPoc}?oppid=${params.oppid}`,
  })
}

// 排行榜/线索
export const leadsRank = (params) => {
  return request({
    method: 'Get',
    url: `${Api.common.topLeads}?tabType=${params.tabType}&dateRange=${params.dateRange}`,
  })
}

// 排行榜/商机
export const oppRank = (params) => {
  return request({
    method: 'Get',
    url: `${Api.common.topOpp}?tabType=${params.tabType}&dateRange=${params.dateRange}`,
  })
}
// 排行榜/意向金净增
export const drRank = (params) => {
  return request({
    method: 'Get',
    url: `${Api.common.topDr}?tabType=${params.tabType}&dateRange=${params.dateRange}`,
  })
}
// 排行榜/大定
export const orderRank = (params) => {
  return request({
    method: 'Get',
    url: `${Api.common.topOrder}?tabType=${params.tabType}&dateRange=${params.dateRange}`,
  })
}
// 排行榜/试乘试驾完成
export const tdCompleteRank = (params) => {
  return request({
    method: 'Get',
    url: `${Api.common.topTDCompleted}?tabType=${params.tabType}&dateRange=${params.dateRange}`,
  })
}
// 排行榜/TD-show
export const tdShowRank = (params) => {
  return request({
    method: 'Get',
    url: `${Api.common.topTDNoshow}?tabType=${params.tabType}&dateRange=${params.dateRange}`,
  })
}

//  调用来源接口
export const getClueSource = () => {
  return request({
    method: 'get',
    url: `${Api.common.channelList}?level=2&code=0201`
  })
}

//  调用来源接口
export const getClueSource2 = () => {
  return request({
    method: 'get',
    url: `${Api.common.channelList}`
  })
}

//  线索来源接口
export const getSource = () => {
  return request({
    method: 'get',
    url: `${Api.common.channelList}`
  })
}

// Customer Profile主数据模板查询
export const customerprofile = () => {
  return request({
    method: 'GET',
    url: Api.common.customerprofile
  })
}

// 组织-获取组织下用户列表
export const userlist = (params) => {
  return request({
    method: 'GET',
    url: Api.common.userlist,
    params: params
  })
}

// 淘客池线索下发分配动作
export const leadstksend = params => {
  return request({
    method: 'put',
    data: params,
    url: Api.common.leadstksend
  })
}

// 淘客池-线索-认领
export const taokeClaim = params => {
  return request({
    method: 'put',
    data: params,
    url: Api.common.taokeClaim
  })
}

// 获取商机跟进页面活动详情
export const getEntertainment = (params) => {
  return request({
    method: 'get',
    url: Api.common.getEntertainment,
    params: params
  })
}

// 拨打电话
export const orgCode = (params) => {
  return request({
    method: 'get',
    url: `${Api.common.orgCode}/${params.orgCode}`
  })
}

// 活动列表
export const getList = () => {
  return request({
    method: 'get',
    url: Api.common.getList
  })
}

// 获取线索跟进详情
export const getDetail = (params) => {
  return request({
    url: Api.common.getleadsinfo,
    method: 'GET',
    params
  })
}

// 获取线索跟进详情
export const getOppInfo = (params) => {
  return request({
    url: Api.common.getOppInfo,
    method: 'GET',
    params
  })
}